import { gql, useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { FormLogin } from '../../forms';
import { getToken, setToken } from '../../utils/token';
import { Container, Content, Logo } from './styles';

function Login() {
    const [cookies, setCookie] = useCookies(["backoffice_session"]);
    const history = useHistory();

    const [login, { loading }] = useMutation(
        gql`
            mutation userLogin($email: String!, $password: String!) {
                userLogin(email: $email, password: $password) {
                    token
                    user {
                        role
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data.userLogin.user.role === "ADMIN") {
                    setCookie("backoffice_session", data.userLogin.token, { path: '/' });
                    setToken(data.userLogin.token);
                }
            }
        }
    );

    if (getToken() || cookies.backoffice_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Container>
            <Content>
                <Logo src={`${process.env.PUBLIC_URL}/img/logo.png`} />
                <FormLogin loading={loading} onValidData={(data) => login({ variables: { ...data } })} />
            </Content>
        </Container>
    );
}

export default Login;
