import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputLabel, Preview, SpanDescription } from './styles';

export default function FormImageInput({ label, name, description, alt, readOnly, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, error } = useField(name);
    const [preview, setPreview] = useState(null);

    const handlePreview = useCallback((e) => {
        const file = e.target.files?.[0];
        if (!file) {
            setPreview(null);
            return;
        }
        const previewURL = URL.createObjectURL(file);
        setPreview({ url: previewURL, mimetype: file.type });
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref) {
                ref.value = '';
                setPreview(null);
            },
            setValue(_, value) {
                if (value) {
                    setPreview(value);
                }
            }
        })
    }, [fieldName, registerField]);

    let filetype = "file";

    if (preview && preview.mimetype) {
        if (preview.mimetype.includes("image")) {
            filetype = "image";
        }
        else if (preview.mimetype.includes("video")) {
            filetype = "video";
        }
    }

    return (
        <>
            {label && <InputLabel>{label}</InputLabel>}
            {(preview) && <Preview>
                {
                    filetype === "image" && <img src={preview.url} alt="Preview" />
                }
                {
                    filetype === "video" && <video width="100%" height="100%" controls>
                        <source src={preview.url} type={preview.mimetype} />
                        Your browser does not support the video tag.
                  </video>
                }
                {
                    filetype === "file" && <div>Preview unavailable. <a href={preview.url} target="_blank">Download file</a></div>
                }
            </Preview>}
            <Input
                type="file"
                ref={inputRef}
                onChange={handlePreview}
                readOnly={readOnly}
                error={error}
                {...rest}
            />
            {description && <SpanDescription>{description}</SpanDescription>}
        </>
    );
};