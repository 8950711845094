import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    width: 100%;
    height: 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.color};
    background-color: ${props => props.background};
`;