import React from 'react';
import Span from '../../../Span';
import { Wrapper } from './styles';

export default function ButtonColumn({ onClick, text }) {
    return (
        <Wrapper onClick={onClick}>
            <Span size={14} height={18} color="white">{text}</Span>
        </Wrapper>
    );
}