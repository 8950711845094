import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { Files, Pages, Users } from '../../pages';
import useAuth from '../../utils/useAuth';
import Sidebar from './components/Sidebar';
import { Wrapper } from './styles';

function Dashboard() {
    return (
        <Wrapper>
            <Sidebar />
            <Switch>
                <Route exact path="/dashboard/pages" component={Pages} />
                <Route exact path="/dashboard/files" component={Files} />
                <Route exact path="/dashboard/users" component={Users} />
                <Route
                    exact
                    path="/dashboard"
                    render={() => <Redirect to="/dashboard/pages" />}
                />
            </Switch>
        </Wrapper>
    );
}

export default useAuth(Dashboard);