import React, { useEffect, useRef } from 'react';
import { Span } from '../../components';
import { ButtonCreate, SelectInput, TextInput } from '../components';
import { Form, FormGroup, FormInputs, FormTitle } from '../styles';
import validator from '../validator';
import schema from './schema';

export default function UserUpdate({ initialData = {}, onValidData, loading }) {
    const formRef = useRef(null);

    const onSubmit = (data) => {
        validator(formRef, schema, data, onValidData);
    }

    useEffect(() => {
        formRef.current.setData({
            ...initialData
        });
    }, [initialData]);

    return (
        <Form ref={formRef} onSubmit={onSubmit}>
            <FormTitle>
                <Span size={24} height={24}>Update user</Span>
            </FormTitle>
            <FormInputs>
                <FormGroup>
                    <TextInput label="Name" name="name" placeholder="" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Email" name="email" placeholder="" type="email" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Password" name="password" placeholder="" type="password" />
                </FormGroup>
                <FormGroup>
                    <SelectInput label="Role" name="role" placeholder="Choose a role" options={[{ label: "Administrator", value: "ADMIN" }, { label: "User", value: "USER" }]} />
                </FormGroup>
            </FormInputs>
            <ButtonCreate type="submit" color="white" background="#3DDABE" loading={loading}>Update</ButtonCreate>
        </Form>
    );
}