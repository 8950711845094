import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    overflow: hidden;
`;

export const Header = styled.div`
    width: 100%;
    height: 56px;
    display: flex;
    background-color: #2F80ED;
    padding-left: 44px;
`;

export const Column = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-flow: row;

    ${props => {
        if (props.columnOptions) {
            return css`
                flex: 0;
                min-width: ${props.columnOptions.width || "300px"};
                justify-content: flex-end;
            `
        }

        if (props.rowOptions) {
            return css`
                flex: 0;
                min-width: ${props.rowOptions.width || "300px"};
                justify-content: flex-end;
                padding-right: 20px;
            `
        }
    }}

`;

export const HeaderButton = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3DDABE;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Row = styled.div`
    height: 48px;
    padding-left: 44px;
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #E7EBF2;
`;

export const Rows = styled.div`
    flex: 1;
    overflow-y: scroll;
    ${Row}:nth-child(even) {
        background-color: #F9FBFF;
    }
`;

export const Pagination = styled.div`
    width: 100%;
    border-top: 1px solid #E7EBF2;
    height: 52px;
    display: flex;
    flex-flow: row;
`;

export const PaginationInfo = styled.div`
    display: flex;
    height: 100%;
    min-width: 80px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #E7EBF2;
    color: #A3B4CC;
`;

export const PaginationButton = styled.div`
    display: flex;
    width: 52px;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #E7EBF2;
    cursor: pointer;
    color: #A3B4CC;
`;