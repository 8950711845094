import React from 'react';
import { Button as StyledButton } from './styles';
import Loader from 'react-loader-spinner';

function Button(props) {
    return (
        <StyledButton {...props} children={props.loading ? <Loader
            type="Oval"
            color="#FFFFFF"
            height={30}
            width={30}
            style={{ marginTop: 5 }}

        /> : props.children} />
    );
}

export default Button