import React from 'react';
import { SvgClose } from '../../../../svgs';
import { Wrapper } from './styles';

function ButtonClose({ onClick }) {
    return (
        <Wrapper onClick={onClick}>
            <SvgClose width={10} height={10} color="#6B7D99" />
        </Wrapper>
    );
}

export default ButtonClose;