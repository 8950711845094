import React, { useEffect, useRef } from 'react';
import { Span } from '../../components';
import { ButtonCreate, ImageInput, TextInput } from '../components';
import { Form, FormGroup, FormInputs, FormTitle } from '../styles';
import validator from '../validator';
import schema from './schema';

export default function FileCreate({ initialData = {}, onValidData, loading }) {
    const formRef = useRef(null);

    const onSubmit = (data) => {
        validator(formRef, schema, data, onValidData);
    }

    useEffect(() => {
        formRef.current.setData({
            ...initialData
        });
    }, [initialData]);

    return (
        <Form ref={formRef} onSubmit={onSubmit}>
            <FormTitle>
                <Span size={24} height={24}>Create file</Span>
            </FormTitle>
            <FormInputs>
                <FormGroup>
                    <TextInput label="Title" name="title" placeholder="" type="text" />
                </FormGroup>
                <FormGroup>
                    <ImageInput label="File" name="file" />
                </FormGroup>
            </FormInputs>
            <ButtonCreate type="submit" color="white" background="#3DDABE" loading={loading}>Create</ButtonCreate>
        </Form>
    );
}