import * as Yup from 'yup';

export default (formRef, schema, data, onValidData) => {
    schema.validate(data, {
        abortEarly: false
    })
        .then(() => {
            formRef.current.setErrors({});
            onValidData(data)
        })
        .catch((err) => {
            if (err instanceof Yup.ValidationError) {
                const errors = {};
                err.inner.forEach(error => {
                    errors[error.path] = error.message;
                });
                formRef.current.setErrors(errors);
            }
        });
}