import React from 'react';
import Loader from 'react-loader-spinner';
import Span from '../Span';
import { Button as StyledButton } from './styles';

function Button({ background, color, text, loading, ...props }) {
    return (
        <StyledButton type="button" {...props} background={background} children={!!loading ? <Loader
            type="Oval"
            color="#FFFFFF"
            height={30}
            width={30}
            style={{ marginTop: "5px" }}
        /> : <Span size={14} height={18} color={color}>{text}</Span>} />
    );
}

export default Button