import styled from 'styled-components';
import { Span as CommonSpan } from '../../../components';

export const TextField = styled.input`
    width: 100%;
    height: 48px;
    background: #E7EBF2;
    border-radius: 10px;
    border: none;
    padding: 16px 24px;
    color: #121F33;

    ::placeholder {
        color: #A3B4CC;
        opacity: 1;
    }

    ${props => {
        if (props.error) {
            return `
                color: #FA1919;
                background-color: rgb(250, 25, 25, 0.3);
            `;
        }
    }}
`;

export const Span = styled(CommonSpan)`
    display: block;
    font-family: 'CircularBold', sans-serif;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;

    :first-letter {
        text-transform: uppercase;
    }
`;

export const SpanDescription = styled(CommonSpan)`
    display: block;
    font-family: 'Circular', sans-serif;
    font-size: 11px;
    line-height: 14px;
    margin-top: 8px;
    color: #A3B4CC;
`;