import gql from 'graphql-tag';
import React, { useEffect, useRef } from 'react';
import { Span } from '../../components';
import client from '../../utils/client';
import { AsyncSelectInput, ButtonCreate, TextInput } from '../components';
import { Form, FormGroup, FormInputs, FormTitle } from '../styles';
import validator from '../validator';
import schema from './schema';

export default function PagePatientUpdate({ initialData = {}, onValidData, loading }) {
    const formRef = useRef(null);

    const onSubmit = (data) => {
        console.log(data)
        validator(formRef, schema, data, onValidData);
    }

    useEffect(() => {
        formRef.current.setData({
            ...initialData
        });
    }, [initialData]);

    const loadOptions = (inputValue, callback) => {
        client.query(
            {
                query: gql`
                query files($search: String) {
                    files(search: $search, perPage: 5, currentPage: 1) {
                        rows {
                            id
                            title
                            url
                        }
                    }
                }
            `,
                variables: { search: inputValue }
            })
            .then((data) => {
                callback(data.data.files.rows.map((file) => ({ value: file.url, label: file.title })));
            });
    };

    return (
        <Form ref={formRef} onSubmit={onSubmit}>
            <FormTitle>
                <Span size={24} height={24}>Update patient</Span>
            </FormTitle>
            <FormInputs>
                <FormGroup>
                    <TextInput label="Header Button Text" name="header_button_text" type="text" />
                </FormGroup>
                {/* HOME */}
                <FormGroup>
                    <AsyncSelectInput label="Logo" name="logo" isImage defaultValue={initialData.logo} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Label" name="home_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Text 1" name="home_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Text 2" name="home_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Text 3" name="home_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Text 4" name="home_text4" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Apple Store Url" name="home_apple_store_url" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Google Play Url" name="home_google_play_url" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Home Image" name="home_image" isImage defaultValue={initialData.home_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Explore Text" name="home_explore_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Home Explore Image" name="home__explore_image" isImage defaultValue={initialData.home__explore_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* BENEFITS */}
                <FormGroup>
                    <TextInput label="Benefits Label" name="benefits_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Text 1" name="benefits_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Text 2" name="benefits_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Benefits Block 1 Image" name="benefits_block1_image" isImage defaultValue={initialData.benefits_block1_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Block 1 Text 1" name="benefits_block1_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Block 1 Text 2" name="benefits_block1_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Benefits Block 2 Image" name="benefits_block2_image" isImage defaultValue={initialData.benefits_block2_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Block 2 Text 1" name="benefits_block2_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Block 2 Text 2" name="benefits_block2_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Benefits Block 3 Image" name="benefits_block3_image" isImage defaultValue={initialData.benefits_block3_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Block 3 Text 1" name="benefits_block3_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Benefits Block 3 Text 2" name="benefits_block3_text2" type="text" />
                </FormGroup>
                {/* SEARCH */}
                <FormGroup>
                    <TextInput label="Search Label" name="search_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Search Image" name="search_image" isImage defaultValue={initialData.search_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Search Text 1" name="search_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Search Text 2" name="search_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Search Text 3" name="search_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Search Text 4" name="search_text4" type="text" />
                </FormGroup>
                {/* APPOINTMENT */}
                <FormGroup>
                    <TextInput label="Appointment Label" name="appointment_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Text 1" name="appointment_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Text 2" name="appointment_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Text 3" name="appointment_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Text 4" name="appointment_text4" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Text 5" name="appointment_text5" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Appointment Image" name="appointment_image" isImage defaultValue={initialData.appointment_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* SERVICE */}
                <FormGroup>
                    <TextInput label="Service Label" name="service_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Service Text 1" name="service_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Service Text 2" name="service_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Service Text 3" name="service_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Service Image" name="service_image" isImage defaultValue={initialData.service_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* PLATFORM */}
                <FormGroup>
                    <TextInput label="Platform Label" name="platform_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Platform Text 1" name="platform_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Platform Text 2" name="platform_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Platform Button Text" name="platform_button_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Platform Image" name="platform_image" isImage defaultValue={initialData.platform_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* DOWNLOAD */}
                <FormGroup>
                    <TextInput label="Download Label" name="download_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Download Title" name="download_title" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Download Text 1" name="download_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Download Text 2" name="download_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Download Text 3" name="download_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Download Apple Store Url" name="download_apple_store_url" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Download Google Play Url" name="download_google_play_url" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Download Image" name="download_image" isImage defaultValue={initialData.download_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* FOOTER */}
                <FormGroup>
                    <AsyncSelectInput label="Footer Logo" name="footer_logo" isImage defaultValue={initialData.footer_logo} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Text 1" name="footer_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Text 2" name="footer_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Privacy Policy Text" name="footer_privacy_policy_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Terms of Use Text" name="footer_terms_of_use_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Cookies Text" name="footer_cookies_text" type="text" />
                </FormGroup>
            </FormInputs>
            <ButtonCreate type="submit" color="white" background="#3DDABE" loading={loading}>Update</ButtonCreate>
        </Form>
    );
}