import styled from 'styled-components';
import CommonSpan from '../../../components/Span';

export const Input = styled.input`
    display: ${({ readOnly }) => readOnly ? "none" : "block"};
    width: 100%;
    height: 48px;
    background: #E7EBF2;
    border-radius: 10px;
    border: none;
    padding: 16px 24px;
    color: #121F33;

    ::placeholder {
        color: #A3B4CC;
        opacity: 1;
    }

    ${props => {
        if (props.error) {
            return `
                color: #FA1919;
                background-color: rgb(250, 25, 25, 0.3);
            `;
        }
    }}
`;

export const InputLabel = styled(CommonSpan)`
    display: block;
    font-weight: 700;
    margin-bottom: 8px;

    :first-letter {
        text-transform: uppercase;
    }
`;

export const Preview = styled.div`
    margin-top: 4px;
    margin-bottom: 8px;

    img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        border-radius: 10px;
    }

    video {
        width: 100%;
        height: 220px;
        border-radius: 10px;
    }
`;

export const SpanDescription = styled(CommonSpan)`
    font-family: 'Circular', sans-serif;
    font-size: 11px;
    line-height: 14px;
    padding-top: 8px;
    color: #A3B4CC;
`;