import React, { useEffect, useRef } from 'react';
import { Span } from '../../components';
import { ButtonCreate, TextInput } from '../components';
import { Form, FormGroup, FormInputs, FormTitle } from '../styles';
import validator from '../validator';
import schema from './schema';

export default function FileUpdate({ initialData = {}, onValidData, loading }) {
    const formRef = useRef(null);

    const onSubmit = (data) => {
        validator(formRef, schema, data, onValidData);
    }

    useEffect(() => {
        formRef.current.setData({
            ...initialData
        });
    }, [initialData]);

    return (
        <Form ref={formRef} onSubmit={onSubmit}>
            <FormTitle>
                <Span size={24} height={24}>Update file</Span>
            </FormTitle>
            <FormInputs>
                {(initialData && initialData.url) && <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <img src={initialData.url} style={{ width: "320px", height: "220px", marginBottom: "16px", objectFit: "cover" }} />
                </div>}
                <FormGroup>
                    <TextInput label="Title" name="title" placeholder="" type="text" />
                </FormGroup>
            </FormInputs>
            <ButtonCreate type="submit" color="white" background="#3DDABE" loading={loading}>Update</ButtonCreate>
        </Form>
    );
}