import React from 'react';
import Span from '../Span';
import { Wrapper, InnerWrapper } from './styles';

export default function Title({ text = "" }) {
    return (
        <Wrapper>
            <InnerWrapper>
                <Span size={24} height={24}>{text}</Span>
            </InnerWrapper>
        </Wrapper>
    );
}