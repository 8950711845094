import styled from 'styled-components';

export const Wrapper = styled.div`
    min-width: 54px;
    height: 24px;
    background-color: #E7EBF2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    margin: 0 4px;
`;