import SvgArrowDown from './ArrowDown';
import SvgClose from './Close';
import SvgSearch from './Search';
import SvgTrash from './Trash';

export {
    SvgSearch,
    SvgClose,
    SvgTrash,
    SvgArrowDown
};
