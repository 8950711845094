import { useField } from '@unform/core';
import React, { useState } from 'react';
import { SvgArrowDown } from '../../../svgs';
import { Option, Select, Span, SpanDescription } from './styles';

export default function FormSelectInput({ label, initialValue = "", name, description, options, readOnly, onChange, placeholder, ...rest }) {
    const inputRef = React.useRef(null);
    const { fieldName, defaultValue = initialValue, registerField, error } = useField(name);
    const [value, setValue] = useState(defaultValue);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            clearValue(ref) {
                ref.value = '';
                setValue('');
            },
            setValue(_, value) {
                setValue(value);
            }
        });
    }, [fieldName, registerField]);

    return (
        <>
            {label && <Span>{label}</Span>}
            <div style={{ position: "relative" }}>
                <SvgArrowDown width={12} height={6} color="#A3B4CC" style={{ position: "absolute", top: "50%", right: 24 }} />
                <Select
                    onChange={(e) => setValue(e.target.value)}
                    ref={inputRef}
                    readOnly={readOnly}
                    error={error}
                    defaultValue={defaultValue}
                    value={value}
                    {...rest}
                >
                    <Option value="" disabled>{placeholder}</Option>
                    {options && options.map((option, index) => (
                        <Option key={index} value={option.value}>{option.label}</Option>
                    ))}
                </Select>
            </div>
            {description && <SpanDescription>{description}</SpanDescription>}
        </>
    );
}