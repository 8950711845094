import React from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ButtonClose from './components/ButtonClose';
import { Content } from './styles';

export default function SlidingModal({ isOpen = false, onClose, children }) {
    return (
        <SlidingPane
            className="sliding-modal"
            overlayClassName="sliding-modal-overlay"
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={onClose}
            width="520px"
        >
            <Content>
                <ButtonClose onClick={onClose} />
                {children}
            </Content>
        </SlidingPane>
    );
}