import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Search, SlidingModal, Table, Title } from '../../components';
import { ButtonColumn, ButtonDelete, ButtonEdit, ModalDelete } from '../../components/Table/components';
import { FormUserCreate, FormUserUpdate } from '../../forms';
import { Wrapper } from './styles';

function Users() {
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const [updateData, setUpdateData] = useState(null);
    const [deleteData, setDeleteData] = useState(null);

    const [search, setSearch] = useState("");

    const [users, setUsers] = useState(null);

    const [queryUsers] = useLazyQuery(
        gql`
            query users($currentPage: Int, $perPage: Int, $search: String) {
                users(currentPage: $currentPage, perPage: $perPage, search: $search) {
                    rows {
                        id
                        name
                        email
                        role
                    }
                    pagination {
                        total
                        currentPage
                        perPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                setUsers(data.users);
            }
        }
    );

    const [userCreate, { loading: loadingUserCreate }] = useMutation(
        gql`
            mutation userCreate($name: String!, $email: String!, $password: String!, $role: ROLE!) {
                userCreate(name: $name, email: $email, password: $password, role: $role) {
                    id
                    name
                    email
                    role
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (_data) => {
                requestQueryUsers();
                setCreateModalIsOpen(false);
            }
        }
    );

    const [userUpdate, { loading: loadingUserUpdate }] = useMutation(
        gql`
            mutation userUpdate($id: ID!, $name: String, $email: String, $password: String, $role: ROLE) {
                userUpdate(id: $id, name: $name, email: $email, password: $password, role: $role) {
                    id
                    name
                    email
                    role
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                const index = users.rows.findIndex((user) => user.id === data.userUpdate.id);
                if (index !== -1) {
                    users.rows[index] = data.userUpdate;
                    setUsers({ ...users });
                }
                setUpdateModalIsOpen(false);
            }
        }
    );

    const [userDelete, { loading: loadingUserDelete }] = useMutation(
        gql`
            mutation userDelete($id: ID!) {
                userDelete(id: $id) {
                    id
                    name
                    email
                    role
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (_data) => {
                requestQueryUsers();
                setDeleteModalIsOpen(false);
            }
        }
    );

    useEffect(() => {
        setUpdateModalIsOpen(!!updateData);
    }, [updateData]);

    useEffect(() => {
        setDeleteModalIsOpen(!!deleteData);
    }, [deleteData]);

    const requestQueryUsers = (params = {}) => {
        const { perPage, currentPage } = params;
        queryUsers({
            variables: {
                perPage: perPage || users.pagination.perPage,
                currentPage: currentPage || users.pagination.currentPage,
                search: search !== "" ? search : null
            }
        });
    }

    useEffect(() => requestQueryUsers({ currentPage: 1, perPage: 10, search }), [search]);

    useEffect(() => requestQueryUsers({ currentPage: 1, perPage: 10 }), []);

    return (
        <>
            <Wrapper>
                <Title text="Users" />
                <Search value={search} onChangeText={(text) => setSearch(text)} />
                <Table
                    columns={[
                        { value: "name", label: "Name" },
                        { value: "email", label: "Email" },
                        { value: "createButton", component: <ButtonColumn onClick={() => setCreateModalIsOpen(true)} text="Create new user" />, options: true }
                    ]}
                    rows={users && users.rows.map((row, index) => ({
                        key: row.id,
                        columns: [
                            { value: row.name },
                            { value: row.email },
                            {
                                component: <>
                                    <ButtonEdit onClick={() => setUpdateData(users.rows[index])} />
                                    <ButtonDelete onClick={() => setDeleteData(users.rows[index])} />
                                </>,
                                options: true
                            }
                        ]
                    }))}
                    pagination={users && users.pagination}
                    paginationPrevious={() => requestQueryUsers({ currentPage: users.pagination.currentPage - 1 })}
                    paginationNext={() => requestQueryUsers({ currentPage: users.pagination.currentPage + 1 })}
                />
            </Wrapper>
            <SlidingModal isOpen={createModalIsOpen} onClose={() => setCreateModalIsOpen(false)}>
                <FormUserCreate
                    loading={loadingUserCreate}
                    onValidData={(data) => userCreate({ variables: data })}
                />
            </SlidingModal>
            <SlidingModal isOpen={updateModalIsOpen} onClose={() => setUpdateData(null)}>
                <FormUserUpdate
                    loading={loadingUserUpdate}
                    initialData={updateData}
                    onValidData={(data) => {
                        if (data.password === "") data.password = undefined;
                        userUpdate({ variables: { id: updateData.id, ...data } })
                    }}
                />
            </SlidingModal>
            <ModalDelete loading={loadingUserDelete} isOpen={deleteModalIsOpen} onClose={() => setDeleteData(null)} onDelete={() => userDelete({ variables: { id: deleteData.id } })} />
        </>
    );
}

export default Users;