import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 72px;
    padding-left: 20px;
`;

export const InnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;