import { useField } from '@unform/core';
import React from 'react';
import ReactSelect from 'react-select';
import { Span, SpanDescription } from './styles';

export default function FormMultiSelectInput({ label, name, description, ...rest }) {
    const inputRef = React.useRef(null);
    const { fieldName, defaultValue, registerField } = useField(name);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            setValue: (_ref, value) => {
                inputRef.current.select.setValue(value)
            },
            getValue: (ref) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                }
                if (!ref.state.value) {
                    return null;
                }
                return ref.state.value.value;
            },
            clearValue: ref => {
                inputRef.current.select.clearValue();
            }
        });
    }, [fieldName, registerField, rest.isMulti]);

    return (
        <>
            {label && <Span>{label}</Span>}
            <div style={{ position: "relative" }}>
                <ReactSelect
                    isClearable={true}
                    defaultValue={defaultValue}
                    ref={inputRef}
                    {...rest}
                />
            </div>
            {description && <SpanDescription>{description}</SpanDescription>}
        </>
    );
}