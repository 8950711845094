import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

@font-face {
  font-family: Circular;
  src: url(${process.env.PUBLIC_URL}/fonts/CircularStdMedium.otf);
}

@font-face {
  font-family: CircularBold;
  src: url(${process.env.PUBLIC_URL}/fonts/CircularStdBold.otf);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Circular', sans-serif;
}

#root, html, body {
  height: 100%;
  font-size: 14px;
  color: #121F33;
}

body {
  font-family: 'Circular', sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

.sliding-modal .slide-pane__content {
  margin: 0;
  padding: 0;
}

.rdw-editor-main {
  border: 1px solid #f1f1f1;
  padding: 0 12px;
}
`;