import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Search, SlidingModal, Table, Title } from '../../components';
import { ButtonColumn, ButtonDelete, ButtonEdit, ModalDelete } from '../../components/Table/components';
import { FormFileCreate, FormFileUpdate } from '../../forms';
import { Wrapper } from './styles';

function Files() {
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const [updateData, setUpdateData] = useState(null);
    const [deleteData, setDeleteData] = useState(null);

    const [search, setSearch] = useState("");

    const [files, setFiles] = useState(null);

    const [queryFiles] = useLazyQuery(
        gql`
            query files($currentPage: Int, $perPage: Int, $search: String) {
                files(currentPage: $currentPage, perPage: $perPage, search: $search) {
                    rows {
                        id
                        title
                        url
                    }
                    pagination {
                        total
                        currentPage
                        perPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                setFiles(data.files);
            }
        }
    );

    const [fileCreate, { loading: loadingFileCreate }] = useMutation(
        gql`
            mutation fileCreate($title: String!, $file: Upload!) {
                fileCreate(title: $title, file: $file) {
                    id
                    title
                    url
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (_data) => {
                requestQueryFiles();
                setCreateModalIsOpen(false);
            }
        }
    );

    const [fileUpdate, { loading: loadingFileUpdate }] = useMutation(
        gql`
            mutation fileUpdate($id: ID!, $title: String) {
                fileUpdate(id: $id, title: $title) {
                    id
                    title
                    url
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                const index = files.rows.findIndex((file) => file.id === data.fileUpdate.id);
                if (index !== -1) {
                    files.rows[index] = data.fileUpdate;
                    setFiles({ ...files });
                }
                setUpdateModalIsOpen(false);
            }
        }
    );

    const [fileDelete, { loading: loadingFileDelete }] = useMutation(
        gql`
            mutation fileDelete($id: ID!) {
                fileDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (_data) => {
                requestQueryFiles();
                setDeleteModalIsOpen(false);
            }
        }
    );

    useEffect(() => {
        setUpdateModalIsOpen(!!updateData);
    }, [updateData]);

    useEffect(() => {
        setDeleteModalIsOpen(!!deleteData);
    }, [deleteData]);

    const requestQueryFiles = (params = {}) => {
        const { perPage, currentPage } = params;
        queryFiles({
            variables: {
                perPage: perPage || files.pagination.perPage,
                currentPage: currentPage || files.pagination.currentPage,
                search: search !== "" ? search : null
            }
        });
    }

    useEffect(() => requestQueryFiles({ currentPage: 1, perPage: 10, search }), [search]);

    useEffect(() => requestQueryFiles({ currentPage: 1, perPage: 10 }), []);

    return (
        <>
            <Wrapper>
                <Title text="Files" />
                <Search value={search} onChangeText={(text) => setSearch(text)} />
                <Table
                    columns={[
                        { value: "title", label: "Title" },
                        { value: "createButton", component: <ButtonColumn onClick={() => setCreateModalIsOpen(true)} text="Create new file" />, options: true }
                    ]}
                    rows={files && files.rows.map((row, index) => ({
                        key: row.id,
                        columns: [
                            {
                                value:
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={row.url} style={{ width: "32px", height: "32px", marginRight: "16px", objectFit: "cover" }} />
                                        {row.title}
                                    </div>
                            },
                            {
                                component: <>
                                    <ButtonEdit onClick={() => setUpdateData(files.rows[index])} />
                                    <ButtonDelete onClick={() => setDeleteData(files.rows[index])} />
                                </>,
                                options: true
                            }
                        ]
                    }))}
                    pagination={files && files.pagination}
                    paginationPrevious={() => requestQueryFiles({ currentPage: files.pagination.currentPage - 1 })}
                    paginationNext={() => requestQueryFiles({ currentPage: files.pagination.currentPage + 1 })}
                />
            </Wrapper>
            <SlidingModal isOpen={createModalIsOpen} onClose={() => setCreateModalIsOpen(false)}>
                <FormFileCreate
                    loading={loadingFileCreate}
                    onValidData={(data) => fileCreate({ variables: data })}
                />
            </SlidingModal>
            <SlidingModal isOpen={updateModalIsOpen} onClose={() => setUpdateData(null)}>
                <FormFileUpdate
                    loading={loadingFileUpdate}
                    initialData={updateData}
                    onValidData={(data) => {
                        if (data.password === "") data.password = undefined;
                        fileUpdate({ variables: { id: updateData.id, ...data } })
                    }}
                />
            </SlidingModal>
            <ModalDelete loading={loadingFileDelete} isOpen={deleteModalIsOpen} onClose={() => setDeleteData(null)} onDelete={() => fileDelete({ variables: { id: deleteData.id } })} />
        </>
    );
}

export default Files;