import { useField } from '@unform/core';
import React from 'react';
import { Span, SpanDescription, TextField } from './styles';

export default function TextInput({ label, description, name, readOnly, ...rest }) {
    const inputRef = React.useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return <>
        {label && <Span>{label}</Span>}
        <TextField ref={inputRef} defaultValue={defaultValue} {...rest} readOnly={readOnly} error={error} />
        {description && <SpanDescription>{description}</SpanDescription>}
    </>;
}