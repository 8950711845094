import gql from 'graphql-tag';
import React, { useEffect, useRef } from 'react';
import { Span } from '../../components';
import client from '../../utils/client';
import { AsyncSelectInput, ButtonCreate, TextInput } from '../components';
import { Form, FormGroup, FormInputs, FormTitle } from '../styles';
import validator from '../validator';
import schema from './schema';

export default function PageMedicUpdate({ initialData = {}, onValidData, loading }) {
    const formRef = useRef(null);

    const onSubmit = (data) => {
        console.log(data)
        validator(formRef, schema, data, onValidData);
    }

    useEffect(() => {
        formRef.current.setData({
            ...initialData
        });
    }, [initialData]);

    const loadOptions = (inputValue, callback) => {
        client.query(
            {
                query: gql`
                query files($search: String) {
                    files(search: $search, perPage: 5, currentPage: 1) {
                        rows {
                            id
                            title
                            url
                        }
                    }
                }
            `,
                variables: { search: inputValue }
            })
            .then((data) => {
                callback(data.data.files.rows.map((file) => ({ value: file.url, label: file.title })));
            });
    };

    return (
        <Form ref={formRef} onSubmit={onSubmit}>
            <FormTitle>
                <Span size={24} height={24}>Update medic</Span>
            </FormTitle>
            <FormInputs>
                <FormGroup>
                    <TextInput label="Header Button Text" name="header_button_text" type="text" />
                </FormGroup>
                {/* HOME */}
                <FormGroup>
                    <AsyncSelectInput label="Logo" name="logo" isImage defaultValue={initialData.logo} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Label" name="home_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Text 1" name="home_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Text 2" name="home_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Text 3" name="home_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Button Text" name="home_button_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Home Image" name="home_image" isImage defaultValue={initialData.home_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Home Explore Text" name="home_explore_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Home Explore Image" name="home__explore_image" isImage defaultValue={initialData.home__explore_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* APPOINTMENT */}
                <FormGroup>
                    <TextInput label="Appointment Label" name="benefits_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Title" name="benefits_title" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Text 1" name="benefits_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Appointment Text 2" name="benefits_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Appointment Image" name="benefits_image" isImage defaultValue={initialData.benefits_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* FIDELITY */}
                <FormGroup>
                    <TextInput label="Fidelity Label" name="fidelity_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Fidelity Text 1" name="fidelity_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Fidelity Text 2" name="fidelity_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Fidelity Button Text" name="fidelity_button_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Fidelity Image" name="fidelity_image" isImage defaultValue={initialData.fidelity_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* PAYMENTS */}
                <FormGroup>
                    <TextInput label="Payments Label" name="payments_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Payments Text 1" name="payments_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Payments Text 2" name="payments_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Payments Image" name="payments_image" isImage defaultValue={initialData.payments_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* BILLING */}
                <FormGroup>
                    <TextInput label="Billing Label" name="billing_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Billing Text 1" name="billing_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Billing Text 2" name="billing_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Billing Image" name="billing_image" isImage defaultValue={initialData.billing_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* IOSANDROID */}
                <FormGroup>
                    <TextInput label="IosAndroid Label" name="iosandroid_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="IosAndroid Text 1" name="iosandroid_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="IosAndroid Text 2" name="iosandroid_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="IosAndroid Button Text" name="iosandroid_button_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="IosAndroid Text 3" name="iosandroid_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="IosAndroid Image" name="iosandroid_image" isImage defaultValue={initialData.iosandroid_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* CLIENTS */}
                <FormGroup>
                    <AsyncSelectInput label="Clients Image" name="clients_image" isImage defaultValue={initialData.clients_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Clients Label" name="clients_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Clients Title" name="clients_title" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Clients Text 1" name="clients_text1" type="text" />
                </FormGroup>
                {/* FILES */}
                <FormGroup>
                    <TextInput label="Files Label" name="files_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Files Text 1" name="files_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Files Text 2" name="files_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Files Image" name="files_image" isImage defaultValue={initialData.files_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* NOTIFICATIONS */}
                <FormGroup>
                    <TextInput label="Notifications Label" name="notifications_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Notifications Text 1" name="notifications_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Notifications Text 2" name="notifications_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Notifications Button Text" name="notifications_button_text" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Notifications Image" name="notifications_image" isImage defaultValue={initialData.notifications_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                {/* FOOTER */}
                <FormGroup>
                    <AsyncSelectInput label="Footer Logo" name="footer_logo" isImage defaultValue={initialData.footer_logo} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Label" name="footer_label" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Title" name="footer_title" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Text 1" name="footer_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Text 2" name="footer_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Footer Text 3" name="footer_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Footer Image" name="footer_image" isImage defaultValue={initialData.footer_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
            </FormInputs>
            <ButtonCreate type="submit" color="white" background="#3DDABE" loading={loading}>Update</ButtonCreate>
        </Form>
    );
}