import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3DDABE;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;