import React from 'react';
import { Button as StyledButton } from './styles';
import Loader from 'react-loader-spinner';

function ButtonCreate({ loading, children, ...props }) {
    return (
        <div>
            <StyledButton {...props} children={!!loading ? <Loader
                type="Oval"
                color="#FFFFFF"
                height={30}
                width={30}
                style={{ marginTop: "5px" }}

            /> : children} />
        </div>
    );
}

export default ButtonCreate