import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Search, SlidingModal, Table, Title } from '../../components';
import { ButtonEdit } from '../../components/Table/components';
import { FormPageEntryUpdate, FormPageMedicUpdate, FormPagePatientUpdate, FormPageTermsUpdate } from '../../forms';
import { Wrapper } from './styles';

function Pages() {
    const [updateTranslationModalIsOpen, setUpdateTranslationModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);

    const [updateDataTranslation, setUpdateDataTranslation] = useState(null);
    const [updateData, setUpdateData] = useState(null);

    const [search, setSearch] = useState("");

    const [pages, setPages] = useState(null);
    const [languages, setLanguages] = useState([]);

    const [queryPages] = useLazyQuery(
        gql`
            query pages($currentPage: Int, $perPage: Int, $search: String) {
                pages(currentPage: $currentPage, perPage: $perPage, search: $search) {
                    rows {
                        id
                        key
                        content
                        translations {
                            id
                            key
                            content
                            language
                            page
                        }
                    }
                    pagination {
                        total
                        currentPage
                        perPage
                    }
                }
                frontendLanguages {
                    id
                    name
                    code
                    default
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                setPages(data.pages);
                setLanguages(data.frontendLanguages);
            }
        }
    );

    const [pageUpdateTranslation, { loading: loadingPageUpdateTranslation }] = useMutation(
        gql`
            mutation pageUpdateTranslation($id: ID!, $language: ID!, $content: Json) {
                pageUpdateTranslation(id: $id, language: $language, content: $content) {
                    id
                    key
                    content
                    translations {
                        id
                        key
                        content
                        language
                        page
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                const index = pages.rows.findIndex((page) => page.id === data.pageUpdateTranslation.id);
                if (index !== -1) {
                    pages.rows[index] = data.pageUpdateTranslation;
                    setPages({ ...pages });
                }
                setUpdateTranslationModalIsOpen(false);
            }
        }
    );

    const [pageUpdate, { loading: loadingPageUpdate }] = useMutation(
        gql`
            mutation pageUpdate($id: ID!, $content: Json) {
                pageUpdate(id: $id, content: $content) {
                    id
                    key
                    content
                    translations {
                        id
                        key
                        content
                        language
                        page
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                const index = pages.rows.findIndex((page) => page.id === data.pageUpdate.id);
                if (index !== -1) {
                    pages.rows[index] = data.pageUpdate;
                    setPages({ ...pages });
                }
                setUpdateModalIsOpen(false);
            }
        }
    );

    useEffect(() => {
        setUpdateModalIsOpen(!!updateData);
    }, [updateData]);

    useEffect(() => {
        setUpdateTranslationModalIsOpen(!!updateDataTranslation);
    }, [updateDataTranslation]);

    const requestQueryPages = (params = {}) => {
        const { perPage, currentPage } = params;
        queryPages({
            variables: {
                perPage: perPage || pages.pagination.perPage,
                currentPage: currentPage || pages.pagination.currentPage,
                search: search !== "" ? search : null
            }
        });
    }

    useEffect(() => requestQueryPages({ currentPage: 1, perPage: 10, search }), [search]);

    useEffect(() => requestQueryPages({ currentPage: 1, perPage: 10 }), []);

    return (
        <>
            <Wrapper>
                <Title text="Pages" />
                <Search value={search} onChangeText={(text) => setSearch(text)} />
                <Table
                    columns={[
                        { value: "key", label: "Key" },
                    ]}
                    rows={pages && pages.rows.map((row, index) => ({
                        key: row.id,
                        columns: [
                            { value: row.key },
                            {
                                component: <>
                                    {languages.map((language) => (
                                        language.default ?
                                            <ButtonEdit key={language.id} text={`${language.name} (default)`} onClick={() => setUpdateData(pages.rows[index])} />
                                            :
                                            <ButtonEdit key={language.id} text={`${language.name}`} onClick={() => setUpdateDataTranslation(pages.rows[index].translations.find((translation) => translation.language === language.id) || { page: pages.rows[index].id, key: pages.rows[index].key, language: language.id })} />
                                    ))}
                                </>,
                                options: { width: "500px" }
                            }
                        ]
                    }))}
                    pagination={pages && pages.pagination}
                    paginationPrevious={() => requestQueryPages({ currentPage: pages.pagination.currentPage - 1 })}
                    paginationNext={() => requestQueryPages({ currentPage: pages.pagination.currentPage + 1 })}
                />
            </Wrapper>
            <SlidingModal isOpen={updateModalIsOpen} onClose={() => setUpdateData(null)}>
                {(updateData && updateData.key === "entry") && <FormPageEntryUpdate
                    loading={loadingPageUpdate}
                    initialData={updateData.content}
                    onValidData={(data) => {
                        pageUpdate({ variables: { id: updateData.id, content: data } })
                    }}
                />}
                {(updateData && updateData.key === "patient") && <FormPagePatientUpdate
                    loading={loadingPageUpdate}
                    initialData={updateData.content}
                    onValidData={(data) => {
                        pageUpdate({ variables: { id: updateData.id, content: data } })
                    }}
                />}
                {(updateData && updateData.key === "medic") && <FormPageMedicUpdate
                    loading={loadingPageUpdate}
                    initialData={updateData.content}
                    onValidData={(data) => {
                        pageUpdate({ variables: { id: updateData.id, content: data } })
                    }}
                />}
                {(updateData && updateData.key === "terms") && <FormPageTermsUpdate
                    loading={loadingPageUpdate}
                    initialData={updateData.content}
                    onValidData={(data) => {
                        pageUpdate({ variables: { id: updateData.id, content: data } })
                    }}
                />}
            </SlidingModal>
            <SlidingModal isOpen={updateTranslationModalIsOpen} onClose={() => setUpdateDataTranslation(null)}>
                {(updateDataTranslation && updateDataTranslation.key === "entry") && <FormPageEntryUpdate
                    loading={loadingPageUpdateTranslation}
                    initialData={updateDataTranslation.content}
                    onValidData={(data) => {
                        pageUpdateTranslation({ variables: { id: updateDataTranslation.page, language: updateDataTranslation.language, content: data } })
                    }}
                />}
                {(updateDataTranslation && updateDataTranslation.key === "patient") && <FormPagePatientUpdate
                    loading={loadingPageUpdateTranslation}
                    initialData={updateDataTranslation.content}
                    onValidData={(data) => {
                        pageUpdateTranslation({ variables: { id: updateDataTranslation.page, language: updateDataTranslation.language, content: data } })
                    }}
                />}
                {(updateDataTranslation && updateDataTranslation.key === "medic") && <FormPageMedicUpdate
                    loading={loadingPageUpdateTranslation}
                    initialData={updateDataTranslation.content}
                    onValidData={(data) => {
                        pageUpdateTranslation({ variables: { id: updateDataTranslation.page, language: updateDataTranslation.language, content: data } })
                    }}
                />}
                {(updateDataTranslation && updateDataTranslation.key === "terms") && <FormPageTermsUpdate
                    loading={loadingPageUpdateTranslation}
                    initialData={updateDataTranslation.content}
                    onValidData={(data) => {
                        pageUpdateTranslation({ variables: { id: updateDataTranslation.page, language: updateDataTranslation.language, content: data } })
                    }}
                />}
            </SlidingModal>
        </>
    );
}

export default Pages;