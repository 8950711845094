import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 48px;
    position: relative;
`;

export const IconWrapper = styled.div`
   position: absolute;
   top: 16px;
   left: 20px;
`;

export const Input = styled.input`
    width: 100%;
    height: 100%;
    background-color: #E7EBF2;
    border: 0;
    margin: 0;
    padding: 16px 52px;
    font-size: 14px;
    line-height: 18px;
    
    ::placeholder {
        color: #A3B4CC;
        opacity: 1;
    }
`;