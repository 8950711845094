import { useField } from '@unform/core';
import React, { useState } from 'react';
import ReactSelect from 'react-select/async';
import { Span, SpanDescription } from './styles';

export default function AsyncSelectInput({ label, name, description, isImage, defaultValue, ...rest }) {
    const inputRef = React.useRef(null);
    const { fieldName, registerField } = useField(name);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: (_ref) => {
                if (rest.isMulti) {
                    if (!inputRef.current.select.state.value) {
                        return [];
                    }
                    return inputRef.current.select.state.value.map((option) => option.value);
                }
                if (!inputRef.current.select.state.value) {
                    return null;
                }
                return inputRef.current.select.state.value.value;
            },
            clearValue: ref => {
                inputRef.current.select.clearValue();
            }
        });
    }, [fieldName, registerField, rest.isMulti]);

    let auxValue = inputRef.current && inputRef.current.select.state.value ? inputRef.current.select.state.value.value : defaultValue;
    if (auxValue) {
        auxValue = { value: auxValue, label: "" };
    }

    const [currentValue, setCurrentValue] = useState(auxValue);

    return (
        <>
            {label && <Span>{label}</Span>}
            <div style={{ position: "relative" }}>
                {(isImage && currentValue) && <img src={currentValue.value} style={{
                    width: "100%",
                    height: "220px",
                    objectFit: "cover",
                    borderRadius: "10px",
                }} />}
                <ReactSelect
                    isClearable={true}
                    ref={inputRef}
                    defaultOptions
                    onChange={(value) => setCurrentValue(value)}
                    defaultValue={currentValue}
                    {...rest}
                />
            </div>
            {description && <SpanDescription>{description}</SpanDescription>}
        </>
    );
}