import { useField } from '@unform/core';
import { convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Span, SpanDescription } from './styles';

export default function EditorInput({ label, description, name, readOnly, ...rest }) {
    const inputRef = React.useRef(null);
    const { fieldName, defaultValue, registerField } = useField(name);
    const [value, setValue] = useState(defaultValue);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: (ref) => {
                return draftToHtml(convertToRaw(ref.state.editorState.getCurrentContent()));
            },
            clearValue(_ref) {
                setValue(null);
            },
            setValue(_, value) {
                if (value) {
                    setValue(EditorState.createWithContent(stateFromHTML(value)));
                }
            }
        });
    }, [fieldName, registerField]);

    return <>
        {label && <Span>{label}</Span>}
        <Editor
            ref={inputRef}
            editorState={value}
            onEditorStateChange={(editorState) => setValue(editorState)}
            toolbar={{
                options: ['inline', 'link']
            }}
            {...rest}
        />
        {description && <SpanDescription>{description}</SpanDescription>}
    </>;
}