import FormFileCreate from './FileCreate';
import FormFileUpdate from './FileUpdate';
import FormLogin from './Login';
import FormPageEntryUpdate from './PageEntryUpdate';
import FormPageMedicUpdate from './PageMedicUpdate';
import FormPagePatientUpdate from './PagePatientUpdate';
import FormPageTermsUpdate from './PageTermsUpdate';
import FormUserCreate from './UserCreate';
import FormUserUpdate from './UserUpdate';

export {
    FormLogin,
    FormUserCreate,
    FormUserUpdate,
    FormPageEntryUpdate,
    FormFileCreate,
    FormFileUpdate,
    FormPagePatientUpdate,
    FormPageMedicUpdate,
    FormPageTermsUpdate
};

