import React from 'react';
import { SvgTrash } from '../../../../svgs';
import { Wrapper } from './styles';

export default function ButtonDelete({ onClick }) {
    return (
        <>
            <Wrapper onClick={onClick}>
                <SvgTrash width={10.4} height={12} color="#FA1919" />
            </Wrapper>
        </>
    );
}