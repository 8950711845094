import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 24px;
    height: 24px;
    background-color: rgba(250,25,25,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 4px;

`;