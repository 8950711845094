import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 220px;
    height: 100%;
    border-right: 1px solid #E7EBF2;
    display: flex;
    flex-flow: column;
`;

export const LogoWrapper = styled.div`
    width: 220px;
    height: 72px;
    padding: 26px 28px;
    display: flex;
    justify-content: center;
`;

export const Logo = styled.img`
    height: 100%;
`;

export const MenuWrapper = styled.div`
    width: 220px;
    flex: 1;
    display: flex;
    flex-flow: column;
`;

export const Link = styled(NavLink)`
    color: #121F33;
    text-decoration: none;
    height: 55px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 30px;

    &.selected {
        border-left: 2px solid #2F80ED;
        padding-left: 28px;
    }

    :first-of-type {
        border-top: 1px solid #E7EBF2;
    }
`;

export const LogoutButton = styled.div`
    height: 52px;
    background-color: #2F80ED;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
`;