import * as React from "react"

function SvgComponent({ width, height, color, ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M12 2L2 12M12 12L2 2"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SvgComponent