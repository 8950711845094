import React from 'react';
import Span from '../Span';
import { Column, Header, Pagination, PaginationButton, PaginationInfo, Row, Rows, Wrapper } from './styles';

export default function Table({ columns = [], rows = [], pagination, paginationPrevious, paginationNext }) {
    const numOfPages = pagination && Math.ceil(pagination.total / pagination.perPage);
    return (
        <Wrapper>
            <Header>
                {columns && columns.map((column) => {
                    if (column.component) {
                        return (
                            <Column key={column.value} columnOptions={column.options}>
                                {column.component}
                            </Column>
                        );
                    }

                    return (
                        <Column key={column.value}>
                            <Span size={11} height={14} color="white">{column.label}</Span>
                        </Column>
                    );
                })}
            </Header>
            <Rows>
                {rows && rows.map((row) => (
                    <Row key={row.key}>
                        {row.columns.map((column, index) => {
                            if (column.component) {
                                return (
                                    <Column key={index} rowOptions={column.options}>
                                        {column.component}
                                    </Column>
                                );
                            }

                            return (
                                <Column key={index}>
                                    <Span size={12} height={24} color="#121F33">{column.value}</Span>
                                </Column>
                            );
                        })}
                    </Row>
                ))}
            </Rows>
            <Pagination>
                {pagination && <>
                    <PaginationInfo>
                        {pagination.currentPage} of {numOfPages === 0 ? 1 : numOfPages}
                    </PaginationInfo>
                    <PaginationButton onClick={() => pagination.currentPage - 1 > 0 && paginationPrevious()}>
                        {"<"}
                    </PaginationButton>
                    <PaginationButton onClick={() => pagination.currentPage + 1 <= numOfPages && paginationNext()}>
                        {">"}
                    </PaginationButton>
                </>
                }
            </Pagination>
        </Wrapper>
    );
}