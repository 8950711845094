import React from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { getToken, setToken } from './token';

export default (Component) => {
    return () => {
        const [cookies] = useCookies(['backoffice_session']);
        const history = useHistory();

        if (cookies.backoffice_session) {
            setToken(cookies.backoffice_session);
        }

        if (getToken()) {
            return <Component />
        } else {
            history.push("/login");
        }

        return null;
    }
}