import gql from 'graphql-tag';
import React, { useEffect, useRef } from 'react';
import { Span } from '../../components';
import client from '../../utils/client';
import { AsyncSelectInput, ButtonCreate, TextInput } from '../components';
import { Form, FormGroup, FormInputs, FormTitle } from '../styles';
import validator from '../validator';
import schema from './schema';

export default function PageEntryUpdate({ initialData = {}, onValidData, loading }) {
    const formRef = useRef(null);

    const onSubmit = (data) => {
        console.log(data)
        validator(formRef, schema, data, onValidData);
    }

    useEffect(() => {
        formRef.current.setData({
            ...initialData
        });
    }, [initialData]);

    const loadOptions = (inputValue, callback) => {
        client.query(
            {
                query: gql`
                query files($search: String) {
                    files(search: $search, perPage: 5, currentPage: 1) {
                        rows {
                            id
                            title
                            url
                        }
                    }
                }
            `,
                variables: { search: inputValue }
            })
            .then((data) => {
                callback(data.data.files.rows.map((file) => ({ value: file.url, label: file.title })));
            });
    };

    return (
        <Form ref={formRef} onSubmit={onSubmit}>
            <FormTitle>
                <Span size={24} height={24}>Update entry</Span>
            </FormTitle>
            <FormInputs>
                <FormGroup>
                    <AsyncSelectInput label="Logo" name="logo" isImage defaultValue={initialData.logo} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Patient Text 1" name="patient_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Patient Text 2" name="patient_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Patient Text 3 " name="patient_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Patient Button Text" name="patient_buttonText" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Patient Image" name="patient_image" isImage defaultValue={initialData.patient_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Medic Text 1" name="medic_text1" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Medic Text 2" name="medic_text2" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Medic Text 3 " name="medic_text3" type="text" />
                </FormGroup>
                <FormGroup>
                    <TextInput label="Medic Button Text" name="medic_buttonText" type="text" />
                </FormGroup>
                <FormGroup>
                    <AsyncSelectInput label="Medic Image" name="medic_image" isImage defaultValue={initialData.medic_image} placeholder="Search..." loadOptions={loadOptions} />
                </FormGroup>
            </FormInputs>
            <ButtonCreate type="submit" color="white" background="#3DDABE" loading={loading}>Update</ButtonCreate>
        </Form>
    );
}