import React from 'react';
import Modal from 'react-modal';
import Button from '../../../Button';
import Span from '../../../Span';
import { ButtonWrapper } from './styles';

Modal.setAppElement("body");

export default function ModalDelete({ isOpen, onClose, onDelete, loading }) {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                overlay: {
                    backgroundColor: "rgba(0,0,0,0.3)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                },
                content: {
                    position: 'initial',
                    width: "375px",
                    background: '#fff',
                    outline: 'none',
                    padding: '32px',
                    border: "none",
                    borderRadius: 0,
                    display: "flex",
                    flexFlow: "column",
                    textAlign: "center"
                }
            }}
        >
            <Span size={16} height={20} color="#FA1919">Delete</Span>
            <Span size={14} height={18} color="#6B7D99" style={{ marginTop: 8 }}>Deleting it’s irreversible, it will erase all the data, do you want to proceed?</Span>
            <ButtonWrapper>
                <Button background="#E7EBF2" color="#6B7D99" text="Cancel" onClick={onClose} style={{ marginRight: 6 }} />
                <Button background="#FA1919" color="white" text="Delete" onClick={onDelete} loading={loading} style={{ marginLeft: 6 }} />
            </ButtonWrapper>
        </Modal>
    );
}