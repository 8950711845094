import React from 'react';
import { SvgSearch } from '../../svgs';
import { IconWrapper, Input, Wrapper } from './styles';

export default function Search({ value, onChangeText }) {
    return (
        <Wrapper>
            <IconWrapper>
                <SvgSearch width={16} height={16} color="#A3B4CC" />
            </IconWrapper>
            <Input value={value} onChange={(e) => onChangeText && onChangeText(e.target.value)} placeholder="Search" />
        </Wrapper>
    );
}