import React, { useEffect, useRef } from 'react';
import { TextInput } from '../components';
import { Form, FormGroup } from '../styles';
import validator from '../validator';
import Button from './Button';
import schema from './schema';

function Login({ initialData = {}, onValidData, loading }) {
    const formRef = useRef(null);

    const onSubmit = (data) => {
        validator(formRef, schema, data, onValidData);
    }

    useEffect(() => {
        formRef.current.setData({
            ...initialData
        });
    }, [initialData])

    return (
        <Form ref={formRef} onSubmit={onSubmit}>
            <FormGroup>
                <TextInput label="Email" name="email" placeholder="Ex: myemail@email.com" type="email" />
            </FormGroup>
            <FormGroup>
                <TextInput label="Password" name="password" placeholder="**************" type="password" />
            </FormGroup>
            <Button type="submit" color="white" background="#3DDABE" loading={loading} style={{ marginTop: "32px" }}>Login</Button>
        </Form>
    );
}

export default Login;