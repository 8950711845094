import * as React from "react"

function SvgComponent({ width, height, color, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" {...props}>
            <circle
                cx={6.744}
                cy={6.744}
                r={5.744}
                stroke={color}
                strokeWidth={1.5}
            />
            <path d="M10.436 10.846L16.59 17" stroke={color} strokeWidth={1.5} />
        </svg>
    )
}

export default SvgComponent
