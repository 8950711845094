import styled from 'styled-components';

export const Content = styled.div`
    padding-top: 112px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
`;