import React from 'react';
import Span from '../../../Span';
import { Wrapper } from './styles';

export default function ButtonEdit({ onClick, text = "Edit" }) {
    return (
        <Wrapper onClick={onClick}>
            <Span size={12} height={15} color="#A3B4CC">{text}</Span>
        </Wrapper>
    );
}