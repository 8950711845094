import * as React from "react"

function SvgComponent({ width, height, color, ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 14" fill="none" {...props}>
      <path
        d="M1.119 3.707A1 1 0 012.113 2.6h8.174a1 1 0 01.994 1.107l-.9 8.4a1 1 0 01-.994.893H3.013a1 1 0 01-.994-.893l-.9-8.4z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path d="M7.4 5v5.6M5 5v5.6" stroke={color} strokeLinecap="round" />
      <path d="M8.2 2.6V1h-4v1.6" stroke={color} strokeLinejoin="round" />
    </svg>
  )
}

export default SvgComponent
