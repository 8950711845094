import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #E7EBF2;
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;