import * as React from "react"

function SvgComponent({ width, height, color, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 12 6" fill="none" {...props}>
            <path
                d="M11 1L6.128 5 1 1"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default SvgComponent
