import Button from './Button';
import Search from './Search';
import SlidingModal from './SlidingModal';
import Span from './Span';
import Table from './Table';
import Title from './Title';

export {
    Span,
    Title,
    Search,
    Table,
    SlidingModal,
    Button
};

