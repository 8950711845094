import { Form as UnFormForm } from '@unform/web';
import styled from 'styled-components';

export const Form = styled(UnFormForm)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
`;

export const FormGroup = styled.div`
    margin-bottom: 16px;
`;

export const FormTitle = styled.div`
    padding: 0 70px 10px 70px;
`;

export const FormInputs = styled.div`
    flex: 1;
    overflow-y: scroll;
    padding: 60px 70px;
`;