import React from 'react';
import { useCookies } from 'react-cookie';
import { Link, Logo, LogoutButton, LogoWrapper, MenuWrapper, Wrapper } from './styles';

export default function Sidebar() {
    const [cookies, setCookie, removeCookie] = useCookies(["backoffice_session"]);

    return (
        <Wrapper>
            <LogoWrapper>
                <Logo src={`${process.env.PUBLIC_URL}/img/logo.png`} />
            </LogoWrapper>
            <MenuWrapper>
                <Link to="/dashboard/pages" activeClassName="selected">
                    Pages
                </Link>
                <Link to="/dashboard/files" activeClassName="selected">
                    Files
                </Link>
                <Link to="/dashboard/users" activeClassName="selected">
                    Users
                </Link>
            </MenuWrapper>
            <LogoutButton onClick={() => {
                removeCookie("backoffice_session", { path: '/' });
                window.location.reload(false);
            }}>
                Logout
            </LogoutButton>
        </Wrapper>
    );
}