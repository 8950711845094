import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyle from './styles/global';
import client from './utils/client';

function Init({ children }) {
    return (
        <>
            <GlobalStyle />
            <ApolloProvider client={client}>
                {children}
            </ApolloProvider>
        </>


    );
}

ReactDOM.render(<Init children={<App />} />, document.getElementById('root'));