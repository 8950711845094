import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Logo = styled.img`
    width: 80px;
    margin-Bottom: 24px;
`;

export const Content = styled.div`
    width: 311px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
`;